<template>
  <div class="course-card">
    <b-card
      no-body
      class="c-pointer"
      :class="{ 'card-cl': type !== 'default' }"
      :id="index"
      @click="
        onCardClick($event);
        onOrgClick($event);
      "
      tabindex="0"
    >
      <b-row class="h-100 lxp-card m-0">
        <b-col class="h-100 lxp-card p-0">
          <b-card-body class="h-100 lxp-card">
            <div class="courses-card__sec1">
              <b-row class="m-0 lxp-card">
                <AdaptiveImage
                  :fallbackPath="
                    course.image_url
                      ? course.image_url
                      : require('@/assets/images/illustration/course-illustration.svg')
                  "
                  altText=""
                  loading="lazy"
                  height="120"
                  width="100%"
                  cssClass="lxp-card__img"
                />
              </b-row>
              <b-row class="m-0 p-3 lxp-card__content">
                <b-col cols="12" class="p-0 position-relative">
                  <div class="lxp-card">
                    <p class="card-label">
                      {{ course.org }}
                    </p>
                    <h4
                      class="card-title"
                      :title="course.title"
                      v-html="
                        cardTitle(
                          course.title ? course.title : course.course_title
                        )
                      "
                    ></h4>
                  </div>
                  <div class="lxp-card__cta">
                    <div class="lxp-cta__actions">
                      <div
                        class="w-100 lx-cta__w100"
                        v-if="type === 'enrolled' || type === 'wishlist'"
                        @click="onButtonClick"
                      >
                        <b-button block class="ob-btn lxp-cta"
                          >{{
                            type === "enrolled"
                              ? "Start Learning"
                              : "Enroll Now"
                          }}
                          ➞</b-button
                        >
                      </div>
                      <div
                        class="courses-card__progress lxp-cta"
                        v-if="type === 'continue_learning'"
                      >
                        <b-progress
                          :value="value"
                          :max="max"
                          height="8px"
                        ></b-progress>
                      </div>
                      <div
                        class="w-100 lx-cta__w100"
                        v-if="
                          type === 'completed' &&
                            course.microcredentials &&
                            course.microcredentials.length > 0
                        "
                        @click="onButtonClick"
                      >
                        <b-button block class="ob-btn lxp-cta"
                          >{{ $t("achievements.view_credential") }} ➞</b-button
                        >
                      </div>
                      <div class="w-100 lx-cta__w100" v-else>
                        <span
                          class="lx-cta__helper"
                          :title="$t(`data.pacing.${course.pacing}`)"
                          >{{ $t(`data.pacing.${course.pacing}`) }}</span
                        >
                        <span class="lx-cta__dot"></span>
                        <span class="lx-cta__helper">{{
                          course.language
                            ? $t(`data.language.${course.language}`)
                            : $t(`data.language.en`)
                        }}</span>
                      </div>
                    </div>
                    <b-dropdown class="ac-mc__dropdown ml-3" v-if="false">
                      <template #button-content>
                        <b-icon
                          icon="three-dots-vertical"
                          class="ac-mc__options"
                          style="color:$vle-primary-text"
                        ></b-icon>
                      </template>
                      <b-dropdown-item @click="openWin(m)">
                        <div class="ac-mc__list">
                          <img
                            src="@/assets/images/icons/wishlist.svg"
                            alt="wishlist"
                            width="auto"
                            height="auto"
                          />
                          <p>Move to wishlist</p>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item @click="shareUrl(m)">
                        <div class="ac-mc__list">
                          <img
                            src="@/assets/images/icons/unenroll.svg"
                            alt="unenroll"
                            width="auto"
                            height="auto"
                          />
                          <p>Unenroll</p>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import AdaptiveImage from "@/components/AdaptiveImage.vue";

export default {
  components: { AdaptiveImage },
  name: "CourseCard",
  props: {
    course: Object,
    index: String,
    showChar: Number,
    type: {
      type: String,
      default: "default"
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ratingArr: [1, 2, 3, 4, 5],
      value: 30,
      max: 100,
      showTooltip: false
    };
  },
  methods: {
    cardTitle(title) {
      let cTitle = "";
      if (title.length > this.showChar) {
        let c = title.substr(0, this.showChar);
        cTitle = c + '<span class="moreellipses">...&nbsp;</span>';
      } else {
        cTitle = title;
      }
      return cTitle;
    },
    onCardClick(e) {
      if (e.target.className.indexOf("lxp-cta") === -1) {
        this.$emit("click", this.course);
      }
    },
    onOrgClick(e) {
      if (e.target.className.indexOf("lxp-cta") === -1) {
        this.$emit("click", this.course);
      }
    },
    onButtonClick() {
      this.$emit("btn-click", this.course);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
[dir="rtl"] {
  .card {
    text-align: right;
  }
}
.card {
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none;
  width: 240px;
  height: 235px;
  margin-right: 20px;
  outline: none;
  text-align: left;

  .card-body {
    padding: 0;
    .lxp-card {
      .card-label {
        letter-spacing: 0.2px;
        color: #52575c;
        margin-bottom: 5px;
        font-size: 12px;
      }
      .card-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.1px;
        color: #52575c;
        width: 90%;
        overflow: hidden;
        max-height: 45px;
      }
      .lxp-card__img {
        object-fit: cover;
        min-width: 240px;
      }
    }
    .courses-card__sec1 {
      height: 100%;
      .lxp-card__content {
        display: flex;
        flex-wrap: nowrap;
        height: calc(100% - 120px);
        padding: 5px 15px !important;
        .lxp-card__cta {
          display: flex;
          align-items: center;
          position: absolute;
          width: 100%;
          bottom: 5px;
          .lxp-cta__actions {
            width: 100%;
            .lx-cta__helper {
              font-size: 11px;
              line-height: 18px;
              letter-spacing: 0.2px;
              color: #52575c;
            }
            .lx-cta__dot {
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 100%;
              background: #e8e8e8;
              margin: 0 10px;
            }
            .lx-cta__w100 {
              display: flex;
              align-items: center;
            }
          }
          .ob-btn {
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: 0.5px;
            color: #0043ac;
            background: #fff !important;
            padding: 0;
            text-transform: capitalize;
            margin-bottom: 3px;
            font-weight: 600;
            text-align: left;
          }
        }
      }
    }

    .courses-card__left {
      .card-label {
        font-weight: 500;
        font-size: 13px;
        line-height: 12px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        color: #0043ac;
        margin-bottom: 10px;
      }
      .card-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
      }
      .card__reviews {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #52575c;
        margin-bottom: 10px;
      }
      .card__time {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #52575c;
        margin-bottom: 0;
        span {
          font-size: 11px;
          line-height: 14px;
          letter-spacing: 0.2px;
          color: #52575c;
          display: block;
          margin-bottom: 4px;
        }
      }
    }
  }
}
@media all and (min-width: 1500px) and (max-width: 2200px) {
  .card-cl {
    .card-body {
      .courses-card__sec2 {
        margin-top: 3%;
      }
    }
  }
}
@media all and (min-width: 1024px) and (max-width: 1350px) {
  .card {
    width: auto;
    margin-right: 10px;
  }
}
@media all and (min-width: 1024px) and (max-width: 1200px) {
  .card {
    .card-body {
      .courses-card__sec1 {
        .lxp-card__content {
          padding: 5px 10px !important ;
        }
      }
    }
  }
  .app-ru,
  .app-fr {
    .slider {
      .lxp-card__content {
        .lxp-card__cta {
          .lxp-cta__actions {
            .lx-cta__helper {
              &:first-child {
                height: 30px;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.courses-card__progress {
  .progress-bar {
    background-color: #0043ac;
  }
}
</style>
