var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course-card"},[_c('b-card',{staticClass:"c-pointer",class:{ 'card-cl': _vm.type !== 'default' },attrs:{"no-body":"","id":_vm.index,"tabindex":"0"},on:{"click":function($event){_vm.onCardClick($event);
      _vm.onOrgClick($event);}}},[_c('b-row',{staticClass:"h-100 lxp-card m-0"},[_c('b-col',{staticClass:"h-100 lxp-card p-0"},[_c('b-card-body',{staticClass:"h-100 lxp-card"},[_c('div',{staticClass:"courses-card__sec1"},[_c('b-row',{staticClass:"m-0 lxp-card"},[_c('AdaptiveImage',{attrs:{"fallbackPath":_vm.course.image_url
                    ? _vm.course.image_url
                    : require('@/assets/images/illustration/course-illustration.svg'),"altText":"","loading":"lazy","height":"120","width":"100%","cssClass":"lxp-card__img"}})],1),_c('b-row',{staticClass:"m-0 p-3 lxp-card__content"},[_c('b-col',{staticClass:"p-0 position-relative",attrs:{"cols":"12"}},[_c('div',{staticClass:"lxp-card"},[_c('p',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.course.org)+" ")]),_c('h4',{staticClass:"card-title",attrs:{"title":_vm.course.title},domProps:{"innerHTML":_vm._s(
                      _vm.cardTitle(
                        _vm.course.title ? _vm.course.title : _vm.course.course_title
                      )
                    )}})]),_c('div',{staticClass:"lxp-card__cta"},[_c('div',{staticClass:"lxp-cta__actions"},[(_vm.type === 'enrolled' || _vm.type === 'wishlist')?_c('div',{staticClass:"w-100 lx-cta__w100",on:{"click":_vm.onButtonClick}},[_c('b-button',{staticClass:"ob-btn lxp-cta",attrs:{"block":""}},[_vm._v(_vm._s(_vm.type === "enrolled" ? "Start Learning" : "Enroll Now")+" ➞")])],1):_vm._e(),(_vm.type === 'continue_learning')?_c('div',{staticClass:"courses-card__progress lxp-cta"},[_c('b-progress',{attrs:{"value":_vm.value,"max":_vm.max,"height":"8px"}})],1):_vm._e(),(
                        _vm.type === 'completed' &&
                          _vm.course.microcredentials &&
                          _vm.course.microcredentials.length > 0
                      )?_c('div',{staticClass:"w-100 lx-cta__w100",on:{"click":_vm.onButtonClick}},[_c('b-button',{staticClass:"ob-btn lxp-cta",attrs:{"block":""}},[_vm._v(_vm._s(_vm.$t("achievements.view_credential"))+" ➞")])],1):_c('div',{staticClass:"w-100 lx-cta__w100"},[_c('span',{staticClass:"lx-cta__helper",attrs:{"title":_vm.$t(`data.pacing.${_vm.course.pacing}`)}},[_vm._v(_vm._s(_vm.$t(`data.pacing.${_vm.course.pacing}`)))]),_c('span',{staticClass:"lx-cta__dot"}),_c('span',{staticClass:"lx-cta__helper"},[_vm._v(_vm._s(_vm.course.language ? _vm.$t(`data.language.${_vm.course.language}`) : _vm.$t(`data.language.en`)))])])]),(false)?_c('b-dropdown',{staticClass:"ac-mc__dropdown ml-3",scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{staticClass:"ac-mc__options",staticStyle:{"color":"$vle-primary-text"},attrs:{"icon":"three-dots-vertical"}})]},proxy:true}],null,false,2813212362)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.openWin(_vm.m)}}},[_c('div',{staticClass:"ac-mc__list"},[_c('img',{attrs:{"src":require("@/assets/images/icons/wishlist.svg"),"alt":"wishlist","width":"auto","height":"auto"}}),_c('p',[_vm._v("Move to wishlist")])])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.shareUrl(_vm.m)}}},[_c('div',{staticClass:"ac-mc__list"},[_c('img',{attrs:{"src":require("@/assets/images/icons/unenroll.svg"),"alt":"unenroll","width":"auto","height":"auto"}}),_c('p',[_vm._v("Unenroll")])])])],1):_vm._e()],1)])],1)],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }